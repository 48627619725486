import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { SessionService } from '../../../../../@core/utils/session.service';
import { faBan, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FarmDTO } from '../../../models/farm';
import { KwFilterDropdownService } from '../../../widgets/kw-filter-dropdown/kw-filter-dropdown.service';
import { MatDialog } from "@angular/material/dialog";
import { FarmDetailComponent } from '../farm-detail/farm-detail.component';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { FarmsTableComponent } from '../farms-table/farms-table.component';

@Component({
  selector: 'kw-farms-list',
  templateUrl: './farms-list.component.html',
  styleUrls: ['./farms-list.component.scss']
})
export class FarmsListComponent implements OnInit {

  public loading: boolean = true;
  public faPlus = faPlus;
  public faBan = faBan;
  public gridName: string = "farmsConfigFilters";

  @ViewChild('farmsTable') farmsTable: FarmsTableComponent;

  constructor(
    public router: Router,
    private session: SessionService,
    public dialogService: MatDialog
  ) { }

  ngOnInit() {
  }

  public onLoaded(){
    this.loading = false;
  }

  public onSelected(farm: FarmDTO) {

    this.session.selectedFarm = farm;
    this.openDialog(true, farm)
  }

  openDialog(IsEdit: boolean, data?: FarmDTO) {
    const farmEditDialog = this.dialogService.open(FarmDetailComponent, {
      width: "500px"
    });
    if (IsEdit) {
      this.session.selectedFarm = data;
    }

    farmEditDialog.afterClosed().subscribe((result) => {
      // this.loading = result;
      // setTimeout(() => {
        if(result) {
          // this.loading = false;
          this.loadData();
        }
      // },1000)
      this.session.selectedFarm = {};
    });
  }

  loadData() {
    if (this.farmsTable) {
      this.loading = true; // Start loading
      this.farmsTable.loadData().then(() => {
        this.loading = false; // End loading after data is loaded
      }).catch((error) => {
        this.loading = false; // Ensure loading is stopped on error
        console.error("Error loading data: ", error);
      });
    }
  }

  clearFilters() {
    //Asif: Clear filters
  }
}

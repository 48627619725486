import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../@core/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { FarmDTO } from '../models/farm';


@Injectable({
  providedIn: 'root'
})
export class FarmsService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
    this.defaultHeaders = this.defaultHeaders.set('Authorization', String(this.authService.getUserToken()));
  }

  public apiFarmsByIdGet(id: number): Observable<FarmDTO> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiFarmsByIdGet.');
    }

    let headers = this.defaultHeaders;

    return this.httpClient.get(`${this.basePath}/api/Farms/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
      }
    );
  }

  public apiFarmsGet(varietyId?: number, includeBlocks?: boolean, includeInactive?: boolean): Observable<Array<FarmDTO>>{

    let queryParameters = new HttpParams();
    if (varietyId !== undefined && varietyId !== null) {
      queryParameters = queryParameters.set('VarietyId', <any>varietyId);
    }

    if (includeBlocks !== undefined && includeBlocks !== null) {
      queryParameters = queryParameters.set('IncludeBlocks', <any>includeBlocks);
    }

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set('IncludeInactive', <any>includeInactive);
    }


    let headers = this.defaultHeaders;


    return this.httpClient.get<FarmDTO[]>(`${this.basePath}/api/Farms`,
      {
        headers: headers,
      }
    );
  }

  public apiFarmsPost(body?: FarmDTO): Observable<FarmDTO> {

    let headers = this.defaultHeaders;

    return this.httpClient.post(`${this.basePath}/api/Farms`, body,
      {
        headers: headers,
      }
    );
  }


  public apiFarmsByIdPut(id: number, body?: FarmDTO): Observable<any>{
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiFarmsByIdPut.');
    }


    let headers = this.defaultHeaders;

    return this.httpClient.put(`${this.basePath}/api/Farms/${encodeURIComponent(String(id))}`, body,
      {
        headers: headers,
      }
    );
  }

  public apiFarmsByIdDelete(id: number): Observable<any>{
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiFarmsByIdDelete.');
    }

    let headers = this.defaultHeaders;


    return this.httpClient.delete(`${this.basePath}/api/Farms/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
      }
    );
  }

  // Get all farms for Users Farm Assignment 
  public getAllFarmsForUsers(): Observable<Array<FarmDTO>>{

    let queryParameters = new HttpParams();

    let headers = this.defaultHeaders;


    return this.httpClient.get<FarmDTO[]>(`${this.basePath}/api/Farms/GetFarmsForUsers`,
      {
        headers: headers,
      }
    );
  }


}

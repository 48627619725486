import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthService } from '../../../../@core/auth/auth.service';
import { environment } from '../../../../../environments/environment';
import { FieldEventType } from '../models/field-event-type';



@Injectable({
  providedIn: 'root'
})
export class FieldEventTypesService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
    this.defaultHeaders = this.defaultHeaders.set('Authorization', String(this.authService.getUserToken()));
  }


    public apiFieldEventTypesByIdGet(id: number): Observable<FieldEventType> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiFieldEventTypesByIdGet.');
        }

        let headers = this.defaultHeaders;

        return this.httpClient.get(`${this.basePath}/api/FieldEventTypes/${encodeURIComponent(String(id))}`,
            {
                headers: headers,
            }
        );
    }

    public apiFieldEventTypesGet(): Observable<Array<FieldEventType>>{

        let headers = this.defaultHeaders;

        return this.httpClient.get<FieldEventType[]>(`${this.basePath}/api/FieldEventTypes`,
            {
                headers: headers,
            }
        );
    }

    public apiFieldEventTypePost( body?: FieldEventType ): Observable<FieldEventType>{


        let headers = this.defaultHeaders;

        return this.httpClient.post(`${this.basePath}/api/FieldEventTypes`, body,
            {
                headers: headers,
            }
        );
    }

    public apiFieldEventTypePut(id: number, body?: FieldEventType ): Observable<FieldEventType> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiFarmsByIdPut.');
        }


        let headers = this.defaultHeaders;

        return this.httpClient.put(`${this.basePath}/api/FieldEventTypes/${encodeURIComponent(String(id))}`, body,
            {
                headers: headers,
            }
        );
    }
    public apiFieldEventTypesDelete(id: number): Observable<any> {
        console.log("API Field EventTypes Delete SERVICE Method called");
      
        let headers = this.defaultHeaders;
      
        headers = headers.set('Authorization', String(this.authService.getUserToken()));
      
        const httpHeaderAcceptSelected: string | undefined = 'application/json';
        if (httpHeaderAcceptSelected !== undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
      
        // to determine the Content-Type header
        const consumes: string[] = [];
      
        return this.httpClient.delete(`${this.basePath}/api/FieldEventTypes/${id}`, {
          headers : headers,
        });
      }
      
}

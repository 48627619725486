//Utility class has some helper methods
export class Utils {
    //check if param is empty
    static IsStringNullOrEmpty(str: any) {
      if (str == null || str == undefined || str == "") {
        return true;
      }
      return false;
    }

    static calculateAcrestoHectares(value: number){
      if(value != 0){
        return value * 0.404686 //fixed value for 1acre to hectare
      }
      return value.toFixed(2);
    }
  }
import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../@core/services/users.service';
import { ErrorService } from '../../../@core/utils/error.service';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '../../../@core/utils/session.service';
import { UserDTO } from '../../../harvest-engine/core/models/user';
import { PlatformModuleDTO } from '../../../harvest-engine/core/models/platform-module';
import { TenantModuleDTO } from '../../../harvest-engine/core/models/tenant-module';
import { forkJoin, combineLatest, Observable } from 'rxjs';
import { TenantDTO } from '../../../harvest-engine/core/models/tenant';
import { NbToastrService } from '@nebular/theme';


@Component({
  selector: 'tenants-detail',
  templateUrl: './tenants-detail.component.html',
  styleUrls: ['./tenants-detail.component.scss']
})
export class TenantsDetailComponent implements OnInit {

  teanantUsers : TenantDTO[];
  tenantLoading: boolean = true;
  tenantId: string;
  platformModule : PlatformModuleDTO[];
  sourcePlatformModule : PlatformModuleDTO[];
  targetPlatformModule : PlatformModuleDTO[];
  tenantModule : TenantModuleDTO[];
  updatedTenantModules : TenantModuleDTO[]; 
  submitting: boolean = false;

constructor(
  private usersService: UsersService,
  private errorService: ErrorService,
  private route: ActivatedRoute,
  private session: SessionService,
  private toastr: NbToastrService,
) { }

 ngOnInit() { 
  this.tenantLoading = true;

  let self = this;
  Observable.forkJoin([
    this.usersService.getUserTenant(),
    this.usersService.apiPlatformodulesGet()
        
      ])
        .subscribe(data => {
          self.teanantUsers = data[0];
          self.platformModule = data[1];
          self.sourcePlatformModule = data[1]; // All modules are source modules initially
          self.targetPlatformModule = [];

          self.tenantLoading = false;
        }, err => {
          self.errorService.handleError(err);
          self.tenantLoading = false;
        });

 }


 onTenantSelect(event: Event): void {
  let self = this;
  this.tenantId = (event.target as HTMLSelectElement).value;
  this.tenantLoading = true;

  if(this.tenantId){
    Observable.forkJoin([
      this.usersService.apiTenantModuleByIdGet(this.tenantId)
    ]).subscribe(
      data => {
        self.tenantModule = data[0];
        if (self.platformModule.length > 0 && self.tenantModule.length > 0) {
          self.sourcePlatformModule = self.platformModule.filter(
            x => !self.tenantModule.some(y => y.platformModuleId === x.platformModuleId)
          );
          self.targetPlatformModule = self.platformModule.filter(
            x => self.tenantModule.some(y => y.platformModuleId === x.platformModuleId)
          );
        }
        this.tenantLoading = false;
      },
      error => {
        this.errorService.handleError(error);
        this.tenantLoading = false;
      }
    );
  }
  }

    onSubmit() {
      this.tenantLoading = true;

      if(this.targetPlatformModule !== undefined &&  this.targetPlatformModule.length > 0){
        this.updatedTenantModules = []
  
        for(let t of this.targetPlatformModule){
          let tenantModuleDTO : TenantModuleDTO = {
            tenantId : this.tenantId,
            platformModuleId: t.platformModuleId,
            platformModule : t,
            tenant : this.teanantUsers.find(x =>x.tenantId == this.tenantId),
  
          }
          this.updatedTenantModules.push(tenantModuleDTO);
        }
      }
  
      this.submitting = true;
      if (this.updatedTenantModules != null && this.updatedTenantModules.length > 0) {
        
        this.usersService.apiAssignTenantModule(this.updatedTenantModules).subscribe(() => {
          this.toastr.success('Successfully Updated ');
          this.submitting = false;
          this.tenantLoading = false;
        }, error => {
          this.errorService.handleError(error);
          this.submitting = false;
          this.tenantLoading = false;
        });
      }
  
    }
  


}



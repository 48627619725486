import { Component, Input, OnInit } from '@angular/core';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { FilterSettingsModel, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DatePipe } from '@angular/common';
import { ConfirmationDialogComponent } from '../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { faEdit } from '@fortawesome/pro-duotone-svg-icons';
import { ReasonCodeChangerPopupComponent } from './reason-code-changer-popup/reason-code-changer-popup.component';

@Component({
  selector: 'mass-balance',
  templateUrl: './mass-balance.component.html',
  styleUrls: ['./mass-balance.component.scss']
})
export class MassBalanceComponent implements OnInit {

  @Input() lotId: number;
  public isLoading: boolean = true;
  public massBalance: any[] = [];
  public reasonCodes: any[] = [];
  public faEdit = faEdit;


  public dateFormat = { type: 'date', format: 'dd/MM/yyyy' };
  public pageSettings: PageSettingsModel = {
    pageSize: 10,
    pageSizes: [10, 25, 50],
  }

  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel"
  }

  reasonEditOptions = {
    params: {
      allowFiltering: true,
      dataSource: ['Reason 1', 'Reason 2', 'Reason 3'], // Replace with your actual reason options
      fields: { text: 'reason', value: 'reason' }
    }
  };

  constructor(
    private errorService: ErrorService,
    private inventoryService: InventoryService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {

    this.inventoryService.getReasonCodes().subscribe((data: any) => {
      this.reasonCodes = data;
    }, err => { this.errorService.handleError(err); });

    this.inventoryService.getLotMassBalance(this.lotId).subscribe((data: any) => {

      // sort the data by date
      data.sort((a, b) => {
        return new Date(a.date) > new Date(b.date) ? 1 : -1;
      });
      
      this.massBalance = data;
      this.isLoading = false;
    }, err => { this.errorService.handleError(err); this.isLoading = false; });
  }

  public includePackout() {
    if (!this.massBalance || this.massBalance.length == 0) return false;
    return this.massBalance.some(x => x.packoutCode && x.packoutCode != null && x.packoutCode != '' && x.packoutCode != undefined);
  }

  public includeProduct(){
    return this.massBalance.some(x => x.productCode && x.productCode != null && x.productCode != '' && x.productCode != undefined);
  }

  editReason(data: any) {
    //open a dialog with the reason options
    const dialogRef = this.dialog.open(ReasonCodeChangerPopupComponent, {
      width: '500px',
      data: { record: data, reasonCodes: this.reasonCodes }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log(result);
      }
    });
  }

}

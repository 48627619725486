// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  isMockEnabled: false, // You have to switch this, when your real back-end is done
  isHopHeadFarms: false,
  // Dev Urls
  //Hi this is sample change to test git push access
  PLATFORM_API_URL: 'https://dev-api.kragworks.com',
  // PLATFORM_API_URL: 'http://localhost:50330',
  // PLATFORM_API_URL: 'http://localhost:8090',
  HOLLINGBERY_API_URL: 'https://dev-hb-api.kragworks.com',
  // HOLLINGBERY_API_URL: 'https://localhost:5001',
  SIGNALR_URL: 'https://dev-api.kragworks.com',

  SPRAY_DATABASE_URL: 'http://127.0.0.1:8000',
  AI_URL: 'http://localhost:3010',

  // Live Stuff
  // PLATFORM_API_URL: 'https://api.kragworks.com',
  // HOLLINGBERY_API_URL: 'https://hb-api.kragworks.com',
  // SIGNALR_URL: 'https://notifications.kragworks.com',
  // AI_URL: 'https://ai.kragworks.com',
  // SPRAY_DATABASE_URL: 'https://spray.kragworks.com',

  MAPBOX_KEY: 'pk.eyJ1IjoiY3ltZmciLCJhIjoiY2twMzltdGYwMDRzaTJvcHQwa3ZwZnV1cyJ9.u8PILD_UdrVhpFvJLNxHEQ',
  //HOLLINGBERY_API_URL: 'https://localhost:5001',
  cloud_name: 'kragworks',
  upload_preset: 'UUUUUUUU',
  enable_signalr: false,

  // spectre_topdown_integrator_url: 'https://topdown.integrations.spectre.api.hectre.com',
  spectre_topdown_integrator_url: 'https://topdown.integrations.spectre.api.hectre.com',
  firebase: {    
    apiKey: "AIzaSyClQmvATGVO0k4ijKwiBuHZAop918dEgc8",
    authDomain: "harvestenginemobilefirebase.firebaseapp.com",
    projectId: "harvestenginemobilefirebase",
    storageBucket: "harvestenginemobilefirebase.appspot.com",
    messagingSenderId: "208395415838",
    appId: "1:208395415838:web:74ecfc4cc4a27e48ff990f",
    measurementId: "G-Q4M1PKX8P5"
  }
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TenantsDetailComponent } from './tenants-detail/tenants-detail.component';
import { RouterModule } from '@angular/router';
import { PickListModule } from 'primeng/picklist';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NbCardModule, NbSpinnerModule } from '@nebular/theme';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatDividerModule, MatFormFieldModule, MatProgressSpinnerModule } from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [TenantsDetailComponent],
  imports: [
    CommonModule,
    PickListModule,
    NbSpinnerModule,
    AngularMultiSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    FontAwesomeModule,
    NbCardModule,
    MatDividerModule,
    RouterModule.forChild([
      {
        path:'',
        component : TenantsDetailComponent
      }
  ]),
  ],
  exports:[
    TenantsDetailComponent,
  ]
})
export class TenantsModule { }


import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { faArrowAltCircleLeft, faPlusCircle, faTimes } from '@fortawesome/pro-solid-svg-icons';
import * as _ from 'underscore';
import { CropVariety } from '../../../../core/models/crop-variety';
import { VarietyService } from '../../../../core/services/variety.service';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { HandlingUnitDetailDTO } from '../../models/handling-unit-detail';
import { InventoryPackageTypeDTO } from '../../models/inventory-package-type';
import { InventoryItemDTO } from '../../models/item';
import { InventoryItemAttributeDTO } from '../../models/item-attribute';
import { InventoryPackageTypeService } from '../../services/inventory-package-type.service';
import { Location } from '@angular/common';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { InventoryItemAttributeValueDTO } from '../../models/item-attribute-value';
import { Observable } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { InventoryLotDTO } from '../../models/inventory-lot';
import { TenantWordsPipe } from '../../../../core/pipes/tenant-words.pipe';
import { ActionTypeForInvetory } from '../../../../../shared/enum';

@Component({
  selector: 'handling-unit-detail-edit',
  templateUrl: './handling-unit-detail-edit.component.html',
  styleUrls: ['./handling-unit-detail-edit.component.scss']
})
export class HandlingUnitDetailEditComponent implements OnInit {

    // Icons  //
    public faArrowAltCircleLeft = faArrowAltCircleLeft;
    public faTimes = faTimes;
    public faPlus = faPlusCircle;
  
    public selectedLot: InventoryLotDTO;
    public searchLot = "";
    public selectedVariety: CropVariety;
    public selectedParent: InventoryItemDTO;
    public allLots: InventoryLotDTO[] = [];
    public filteredLotOptions: InventoryLotDTO[] = [];
    public defaultLotSearchPrompt = `Please search for a ${this.pipe.transform("lot")}.`;
    public lotSearchPrompt: string = `Please search for a ${this.pipe.transform("lot")}.`;
    public lotId: number;

    public hud: HandlingUnitDetailDTO = {};
    public items: InventoryItemDTO[];
    public itemAttrabutesDTO: InventoryItemAttributeDTO[];
    public varieties: CropVariety[];
    public isLoading: boolean = false;
    public packageTypes: InventoryPackageTypeDTO[] = [];


    constructor(
      private inventoryService: InventoryService,
      private packageTypeService: InventoryPackageTypeService,
      private router: Router,
      private bottomSheetReturn: MatBottomSheet,
      private matDialog: MatDialog,
      private changeDetRef: ChangeDetectorRef,
      private location: Location,
      private varietyService: VarietyService,
      private errorService: ErrorService,
      private toastr: NbToastrService,
      private pipe: TenantWordsPipe,
      ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.hud = {...this.inventoryService.selectedHUD};
    this.lotId = this.hud.lotId;

    this.filteredLotOptions = [{
      id: this.lotId,
      lotNumber: this.hud.lotNumber,
    }]

    Observable.forkJoin([
      this.inventoryService.getInventoryItems(),
      this.varietyService.getVarieties(false),
      this.inventoryService.getInventoryItemAttributes(),
      this.packageTypeService.getAll(),
      this.inventoryService.getLots()
    ])
    .subscribe(x => {
        this.items = _.sortBy(x[0], 'name').reverse();
        this.varieties = _.sortBy(x[1], 'name');
        this.itemAttrabutesDTO = x[2];
        this.packageTypes = _.sortBy(x[3], 'name');
        this.allLots = x[4];
        this.getCustomFields();
        })
  }

  getOptionsLength(attribute: InventoryItemAttributeValueDTO) {
    if (this.itemAttrabutesDTO) {
      if(this.itemAttrabutesDTO.find(x => x.id == attribute.inventoryItemAttributeId)) {
        return this.itemAttrabutesDTO.find(x => x.id == attribute.inventoryItemAttributeId).options.length;
      }
    } else {
      return [];
    }
  }

  ClearValue(field) {
    var currentField = this.hud.itemAttributeValues.find(x => x.id == field.id);
    currentField.value = null;
  }

  getFieldName(field: InventoryItemAttributeValueDTO) {
    var currentField = this.itemAttrabutesDTO.find(x => x.id == field.inventoryItemAttributeId);
    return currentField.name
  }

  getFieldOptions(field) {
    var currentField = this.itemAttrabutesDTO.find(x => x.id == field.inventoryItemAttributeId);
    return currentField.options
  }

  getCustomFields() {
    if (!this.hud.itemAttributeValues)
      this.hud.itemAttributeValues = [];

    this.itemAttrabutesDTO.forEach(f => {

      var tempField = {...this.hud.itemAttributeValues.find(x => x.inventoryItemAttributeId == f.id)};
      if (tempField) {
        tempField.description = f.description;
        tempField.name = f.name;
        tempField.inventoryItemId = this.hud.id;
        this.isLoading = false;
      }
      else {
        let fieldValue: InventoryItemAttributeValueDTO = {
          datatype: f.datatype,
          description: f.description,
          name: f.name,
          id: 0,
          inventoryItemAttributeId: f.id,
          isActive: true
        };
        this.isLoading = false;
        this.hud.itemAttributeValues.push({...fieldValue});
      }
    });

    this.isLoading = false;
    this.changeDetRef.detectChanges();
  }

  cancel() {
    this.bottomSheetReturn.dismiss();
  }

  submit() {
    
    this.isLoading = true;
    //set ERP flag true to save inventory in ERP from summary
    let isERPSave = true;

    //Passing action type based update Inventory
    let actionType = ActionTypeForInvetory.UpdateInventory;
    
    this.inventoryService.putInventoryHUD(this.hud,isERPSave,actionType).subscribe(x => {
      this.isLoading = false;
      this.toastr.success('Product has been updated', 'SUCCESS');
      this.bottomSheetReturn.dismiss();
      // this.changeDetRef.detectChanges();
    }, err => {
      this.isLoading = false;
      this.errorService.handleError(err);
    });
  }

  lotSelectionChanged() {
    let selectedLot = this.allLots.find(x => x.id == this.lotId);

    if (selectedLot) {
      this.hud.lotId = selectedLot.id;
      this.hud.lotNumber = selectedLot.lotNumber;
    }
    else{
      this.hud.lotId = null;
      this.hud.lotNumber = null;
    }

  }

  filterLots() {
    if (this.allLots) {
      if (!this.searchLot)
        this.searchLot = "";

      let filterValue = this.searchLot.toLowerCase();
      let results = this.allLots.filter(option => option.lotNumber && option.lotNumber.toLowerCase().includes(filterValue));

      if (results.length > 40 && !this.lotId) {
        this.filteredLotOptions = [];
        this.lotSearchPrompt = `Too many ${this.pipe.transform("lots")} found... please narrow your search.`
      } else {
        this.filteredLotOptions = _.sortBy([...results], x => x.lotNumber);
        this.lotSearchPrompt = this.defaultLotSearchPrompt;
      }
    }
  }

  deleteProduct() {
    this.isLoading = true;
    this.inventoryService.selectedHUD = this.hud;
    this.inventoryService.deleteInventoryHUD(this.hud.id).subscribe(x => {
      this.isLoading = false;
      this.toastr.success('Product has been deleted', 'SUCCESS');
      this.bottomSheetReturn.dismiss();
      this.changeDetRef.detectChanges();
    }, err => {
      this.isLoading = false;
      this.errorService.handleError(err);
    });
  }

  porductChange(){
    let product = this.items.find(x => x.id == this.hud.itemId);
    let variety = this.varieties.find(x => x.id == product.varietyId);

    this.hud.itemDescription = product.description;
    this.hud.varietyName = variety.name;
    this.hud.parentItem = product.parentItemName;
    this.hud.packageName = product.packageTypeName;

    this.changeDetRef.detectChanges();
  }

}

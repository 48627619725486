import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { ErrorService } from '../../../../../../@core/utils/error.service';

@Component({
  selector: 'reason-code-changer-popup',
  templateUrl: './reason-code-changer-popup.component.html',
  styleUrls: ['./reason-code-changer-popup.component.scss']
})
export class ReasonCodeChangerPopupComponent implements OnInit {

  isLoading = false;
  originalReason: string;

  constructor(
    private dialogRef: MatDialogRef<ReasonCodeChangerPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private inventoryService: InventoryService,
    private errorService: ErrorService
  ) {
    this.originalReason = data.record.reason;
    this.dialogRef.backdropClick().subscribe(() => {
      data.record.reason = this.originalReason;
      this.dialogRef.close();
    });
  }

  ngOnInit() {
  }

  close() {
    this.data.record.reason = this.originalReason;
    this.dialogRef.close();
  }

  save() {
    this.isLoading = true;
    this.inventoryService.updateMassBalanceReason(this.data.record).subscribe(
      () => {
        this.isLoading = false;
        this.dialogRef.close(this.data.record);
      },
      err => {
        this.isLoading = false;
        this.errorService.handleError(err);
      }
    );
  }

}